<div (mouseenter)="onMouseOver()" (mouseleave)="onMouseOut()">
  <div class="menu-container">
    <div class="menu-container__top">
      <div class="menu-core" *ngFor="let constItem of coreMenu" (click)="onMenuClick()">
        <troi-icon [icon]="constItem.iconName" [size]="constItem.size"></troi-icon>
      </div>
      <div class="menu-core" (click)="onMenuClick()">
        <menu-core-icon [open]="isMenuOpen"></menu-core-icon>
      </div>

            <div class="menu-quick-links-container" [ngClass]="{'menu-quick-links-container--placeholders': isDragStarted } ">
                <menu-quick-link *ngIf="loading" [skeleton]="loading"></menu-quick-link>
                <div [hidden]="isDragStarted">
                    <menu-quick-link  *ngFor="let quickLink of quickLinks" [item]="quickLink" [active]="activeQuickLink" (dragStarted)="onDragStarted($event, true)"></menu-quick-link>
                </div>
                <ng-container *ngIf="isDragStarted">
                    <div class="menu-quick-links-container__placeholder" (drop)="onDrop($event, i)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"  *ngFor="let quickListPlaceholder of [1,2,3,4,5,6]; let i = index" [ngClass]="{ 'menu-quick-links-container__placeholder--empty': !hasSlotIcon(i)}">
                        <troi-icon class="menu-quick-links-container__placeholder-icon" *ngIf="hasSlotIcon(i)" [icon]="quickLinks[i].iconName" [size]="'24px'"></troi-icon>
                    </div>
                </ng-container>
                <div class="menu-quick-links-container__active-menu" *ngIf="!activeQuickLink && activeMenu?.link">
                    <menu-quick-link [item]="activeMenu" [active]="activeMenu"></menu-quick-link>
                </div>
            </div>
        </div>
        <div class="menu-container__bottom">
            <menu-stopwatch></menu-stopwatch>
            <menu-user></menu-user>
        </div>
    </div>
    <div class="menu-backdrop" *ngIf="isMenuOpen" (click)="onClickBackdrop()"></div>
    <div class="menu-wrapper">
        <menu-layer #menuLayerComponent
                    [menu]="menuToDisplay"
                    [loading]="loading"
                    [isOpen]="isMenuOpen" [title]="'Menu.menu' | translate"
                    [footer]="version"
                    [searchMode]="isSearchDisplayed"
                    [searchText]="searchText"
                    (transitionEnded)="onTransitionEnded($event)"
                    (dragStarted)="onDragStarted($event)">
            <troi-input-field
                    *ngIf="isMenuOpen"
                    focus-directive
                    cssSelector=".troi-input-field__input"
                    [ngModel]="searchText"
                    [value]="searchText"
                    (ngModelChange)="onSearchTextChange($event)"
                    (enterPressed)="handleEnterPressed()"
                    [rounded]="true"
                    [icon]="'icon-loop'"
                    [height]="'36px'"
                    [showClearButton]="true"
                    [placeholder]="'Common.labels.search' | translate">
            </troi-input-field>
        </menu-layer>
    </div>
</div>
