import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuQuickLinkComponent } from './components/menu-quick-link/menu-quick-link.component';
import { MenuLayerComponent } from './components/menu-layer/menu-layer.component';
import { MenuUserComponent } from './components/menu-user/menu-user.component';
import { MenuStopwatchComponent } from './components/menu-stopwatch/menu-stopwatch.component';
import { MenuCoreIconComponent } from './components/menu-core-icon/menu-core-icon.component';
import { MenuNetwork } from './network/menu.network';
import { MenuService } from './services/menu.service';
import { SharedModule } from '../../shared/shared.module';
import { StopwatchService } from '../desk/widgets/stopwatch/stopwatch.service';
import { DeskSettingsService } from '../desk/services/desk-settings.service';
import { DeskNetwork } from '../desk/network/desk.network';

import ErrorHandlerService from '../desk/shared/error-handling/ErrorHandlerService';

const components = [
  MenuContainerComponent,
  MenuItemComponent,
  MenuQuickLinkComponent,
  MenuLayerComponent,
  MenuUserComponent,
  MenuStopwatchComponent,
  MenuCoreIconComponent,
];
const providers = [MenuNetwork, MenuService, StopwatchService, DeskSettingsService, DeskNetwork, ErrorHandlerService];

const appRoutes: Routes = [
  {
    path: '',
    component: MenuContainerComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    HeaderNotificationModule,
    NgbModule,
    SharedModule,
    RouterModule.forChild(appRoutes),
  ],
  declarations: [...components],
  providers: [...providers],
  bootstrap: [],
  exports: [...components],
})
export class MenuModule {}
